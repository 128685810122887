
// ElementQueries.listen();


(function($){



    // Search form in header
    jQuery('.header-search button').on('click', function(){

        // If search input is empty and the input is NOT focused
        if ( jQuery('.header-search input').val() == '' && jQuery('.header-search input').hasClass('is-inactive') ) {
            // ... then focus the input and swap state classes
            jQuery('.header-search input').focus().addClass('is-active').removeClass('is-inactive');
            // don't submit form
            return false;

        // If search input is empty and the input is focused
        }  else if ( jQuery('.header-search input').val() == '' && jQuery('.header-search input').hasClass('is-active') ) {
            // ... then blur the input and swap state classes
            jQuery('.header-search input').blur().addClass('is-inactive').removeClass('is-active');
            // don't submit form
            return false;
        }

    });




    // Slick Carousel
    jQuery('.image-gallery').slick({
        asNavFor: '.image-gallery-thumbs, .image-gallery-captions',
        dots: true,
        draggable: false,
        infinite: true,
    });

    jQuery('.image-gallery-thumbs').slick({
        asNavFor: '.image-gallery, .image-gallery-captions',
        // centerMode: true,
        focusOnSelect: true,
        draggable: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        infinite: true,
        // mobileFirst: true,
        respondTo: 'slider',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                }
            },
            // {
            //     breakpoint: 320,
            //     settings: {
            //         slidesToShow: 2,
            //     }
            // },
        ],
    });


    jQuery('.image-gallery-captions').slick({
        arrows: false,
        draggable: false,
        fade: true,
        infinite: true,
    });




    // Initialize FancyBox
    // but only on images that aren't cloned
    jQuery().fancybox({
        selector : '.image-gallery .slick-slide:not(.slick-cloned) a'
    });




    // Description Toggle
    // If height is less than X then collapse the content
    // and show the expand button

    if (Foundation.MediaQuery.atLeast('large')) {
        var descCollapsedHeight = jQuery('.image-gallery').outerHeight() + jQuery('.image-gallery-thumbs').outerHeight() + jQuery('.image-gallery-captions').outerHeight() - jQuery('.product-intro-col .stock-num-quote').outerHeight();

        if ( jQuery('.product-desc').height() >= descCollapsedHeight + 10 ) {
            jQuery('.product-desc').addClass( 'is-collapsed' );
            jQuery('.product-desc').css( 'height', descCollapsedHeight - 60 );
            jQuery('button.product-desc-expand').addClass( 'is-visible' );
        }
    }

    jQuery(window).on('changed.zf.mediaquery', function(event, large, medium) {
        jQuery('.product-desc').removeClass( 'is-collapsed' );
        jQuery('.product-desc').css( 'height','auto' );
        jQuery('button.product-desc-expand').removeClass( 'is-visible' );
    });

    // product-desc-expand button actions
    jQuery('button.product-desc-expand').on('click', function(){
        // Get height of inner container
        var descHeight = jQuery('.product-desc__inner').height();
        // and set height
        // animation is controlled by CSS
        jQuery('.product-desc').css( 'height', descHeight );

        // Hide the expand button
        jQuery(this).removeClass('is-visible');

        // Wait 200ms before removing the inline height
        setTimeout(
            function(){
                jQuery('.product-desc').css( 'height', 'auto' ).removeClass('is-collapsed');
            }, 200
        );
    });






    $('.home-hero-carousel').slick({
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        pauseOnFocus: false,
        pauseOnHover: false,
        fade: true,
        cssEase: 'linear'
    });


    $('.testimonial-carousel').slick({
        fade: true,
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 30000,
        speed: 500,
        pauseOnFocus: false,
        pauseOnHover: false,
        cssEase: 'linear'
    });


})(jQuery);
